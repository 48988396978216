import React from "react"

import SortingTableComponent from '../components/sorting.table';

export default function Home() {
  return (
  	<>
	  	<div>This is a demonstration of a simple leaderboard for a Among Us ranked game service. Mock data for demo.</div>
	  
	  	<SortingTableComponent />
	  </>
  )
}
